import clsx from 'clsx';
import { type CSSProperties, useCallback } from 'react';

import useViewer from '../../hooks/useViewer/useViewer';
import { useAppDispatch } from '../../hooks/redux';
import type { Pano } from '../../types/carousel';
import { showPano } from '../../stores/slices/media';
import { CLOSE_MEDIA_ID } from '../../constants/ids';
import { setCarouselVisible } from '../../stores/slices/ui';

import styles from './CloseMedia.module.scss';

interface CloseMediaProps {
  homePano: Pano;
  className?: string;
  style?: CSSProperties;
}

export default function CloseMedia({
  homePano,
  className,
  style,
}: CloseMediaProps) {
  const { panoId, viewer } = useViewer();
  const dispatch = useAppDispatch();

  const setPano = useCallback(() => {
    dispatch(showPano());
    dispatch(setCarouselVisible(true));

    if (panoId !== homePano.panoid) {
      viewer?.setPano(homePano.panoid, { pov: homePano.pov });
    }
  }, [dispatch, panoId, viewer, homePano]);

  return (
    <div
      id={CLOSE_MEDIA_ID}
      className={clsx(styles.closeButton, className)}
      onClick={setPano}
      style={style}
    >
      &times;
    </div>
  );
}
