import { animated, useSpring } from '@react-spring/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';

import {
  Carousel as CarouselConfig,
  Pano as ScenePano,
} from '../../../../types/carousel';
import { showPano } from '../../../../stores/slices/media';
import {
  getSceneListVisible,
  setSceneListVisible,
} from '../../../../stores/slices/ui';

import { SCENES_ID } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useOnClickOutside from '../../../../hooks/useOnClickOutside/useOnClickOutside';
import useViewer from '../../../../hooks/useViewer/useViewer';

import { ReactComponent as Arrow } from './assets/arrow.svg';

import styles from './SceneSelector.module.scss';

interface SceneSelectorProps {
  className?: string;
  config: CarouselConfig;
}

export default function SceneSelector({
  className,
  config,
}: SceneSelectorProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { viewer, panoId } = useViewer();

  const sceneListVisible = useAppSelector(getSceneListVisible);
  const dispatch = useAppDispatch();
  const closeOnOutsideClick = () => dispatch(setSceneListVisible(false));

  const media = useAppSelector((s) => s.media);

  const toggleSceneList = () => {
    if (media.type === 'pano') {
      dispatch(setSceneListVisible(!sceneListVisible));
    }
  };

  const sceneListAnimation = useSpring({
    bottom: sceneListVisible ? 60 : 0,
    opacity: sceneListVisible ? 1 : 0,
    transform: `scaleY(${sceneListVisible ? 1 : 0.97})`,
    display: sceneListVisible ? 'block' : 'none',
  });

  const sceneSelectorAnimation = useSpring({
    opacity: media.type !== 'pano' ? 0 : 1,
  });

  const setPano = useCallback(
    (pano: ScenePano) => {
      dispatch(showPano());
      dispatch(setSceneListVisible(false));
      if (pano) {
        if (panoId !== pano.panoid) {
          viewer?.setPano(pano.panoid, { pov: pano?.pov });
        }
      }
    },
    [dispatch, panoId, viewer]
  );

  useOnClickOutside(containerRef, closeOnOutsideClick);

  const [displayTitle, setDisplayTitle] = useState('');
  useEffect(() => {
    const newTitle = config.pano.find((p) => p.panoid === panoId)?.title;
    if (newTitle) {
      setDisplayTitle(newTitle);
    }
  }, [config.pano, panoId]);

  return (
    <animated.div
      ref={containerRef}
      style={sceneSelectorAnimation}
      className={clsx(styles.sceneSelectorContainer, className)}
      onClick={toggleSceneList}
      data-cy="scene-selector"
      id={SCENES_ID}
    >
      <div className={styles.sceneSelector}>
        <div className={styles.selectedPanoTitle}>{displayTitle}</div>

        <Arrow
          className={clsx(styles.sceneSelectorArrow, {
            [styles.sceneSelectorArrowUp]: sceneListVisible,
          })}
        />
      </div>

      <animated.ul
        style={sceneListAnimation}
        data-cy="scene-list"
        className={clsx(styles.sceneList, {
          [styles.sceneListOpen]: sceneListVisible,
        })}
      >
        <SimpleBar style={{ maxHeight: '400px' }}>
          {config.pano
            .filter((p) => p.carousel)
            .map((pano) => (
              <li
                key={pano.panoid}
                data-cy={'scene-list-' + pano.title}
                className={`${styles.dropdownItem} ${
                  pano.panoid === panoId ? styles.activeDropdownItem : ''
                }`}
                title={pano.title}
                onClick={() => setPano(pano)}
              >
                <div className={styles.selectedPanoTitle}>{pano.title}</div>
              </li>
            ))}
        </SimpleBar>
      </animated.ul>
    </animated.div>
  );
}
