import React from 'react';
import styles from './Hamburger.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import {
  getCarouselVisible,
  setCarouselVisible,
} from '../../../../../stores/slices/ui';

function HamburgerIcon() {
  return (
    <div className={styles.iconContainer} aria-label="Toggle menu visibility">
      <div className={styles.firstLine}></div>
      <div className={styles.secondLine}></div>
      <div className={styles.thirdLine}></div>
    </div>
  );
}

export default function Hamburger() {
  const dispatch = useAppDispatch();
  const visible = useAppSelector(getCarouselVisible);
  const toggleVisibility = () => dispatch(setCarouselVisible(!visible));

  return (
    <div
      className={styles.container}
      onClick={toggleVisibility}
      data-cy="hamburger"
    >
      <HamburgerIcon />
    </div>
  );
}
