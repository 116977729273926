import React from 'react';
import styles from './FullscreenContent.module.scss';
import Portal from '../../../../../../../components/Portal/Portal';
import { ReactComponent as Close } from '../../../../../../../components/Modal/assets/close.svg';

interface FullscreenContentProps {
  url: string;
  onClose: () => void;
}

export default function FullscreenContent({
  url,
  onClose,
}: FullscreenContentProps) {
  return (
    <Portal className={styles.container}>
      <Close className={styles.close} onClick={onClose} data-cy="close-modal" />
      <iframe src={url} height="100%" width="100%" title={url} />
    </Portal>
  );
}
