import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import clsx from 'clsx';
import styles from './MenuButtons.module.scss';
import {
  MenuButton as IMenuButton,
  MenuItemType,
  Tour,
} from '../../../../../types';

import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Share } from './assets/share.svg';
import { ReactComponent as Contact } from './assets/contact.svg';
import { ReactComponent as Floorplan } from './assets/floorplan.svg';
import { ReactComponent as Fullscreen } from './assets/fullscreen.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as Up } from './assets/up.svg';
import { ReactComponent as Down } from './assets/down.svg';
import { ReactComponent as PhotoIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/camera.svg';
import onEnter from '../../../../../utils/onEnter/onEnter';
import useWindowSize from '../../../../../hooks/useWindowSize/useWindowSize';
import { useAppSelector } from '../../../../../hooks/redux';
import {
  makeMenuButtonID,
  MENU_BUTTONS_ID,
} from '../../../../../constants/ids';
import { Image, Pano, Video } from '../../../../../stores/slices/media';

const SCROLL_STEP = 100;

const ICONS_CONFIG: Record<string, FunctionComponent> = {
  'icon-tour-info': Info,
  'fa-info': Info,
  'icon-book-now': Info,
  'icon-floorplan': Floorplan,
  'icon-share': Share,
  'icon-contact': Contact,
  'icon-fullscreen': Fullscreen,
  'icon-closefullscreen': Fullscreen,
  'fa-map-marker': Map,
};

interface FontAwesomeIconProps {
  icon: string;
}

function FontAwesomeIcon({ icon }: FontAwesomeIconProps) {
  return (
    <i
      className={`${styles.fontAwesomeIcon} fa ${icon}`}
      aria-hidden="true"
    ></i>
  );
}

interface MenuButtonProps {
  icon: string;
  id: string;
  onClick: () => void;
}

const MenuButton = React.memo(({ icon, id, onClick }: MenuButtonProps) => {
  const Icon = ICONS_CONFIG[icon] || Info;
  const isFontAwesomeIcon =
    icon.startsWith('fa-') && icon !== 'fa-info' && icon !== 'fa-map-marker';

  return (
    <div
      role="button"
      id={makeMenuButtonID(id)}
      className={`${styles.control}`}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      tabIndex={0}
    >
      <div
        className={styles.menuButton}
        data-cy={`menu-button-${id}`}
        onClick={onClick}
      >
        {icon === 'image' ? (
          <PhotoIcon />
        ) : icon === 'video' ? (
          <VideoIcon />
        ) : isFontAwesomeIcon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          <Icon />
        )}
      </div>
    </div>
  );
});

interface MenuButtonsProps {
  tour: Tour;
  menuButtons: IMenuButton[];
  onMenuButtonClick(
    id: string,
    type: MenuItemType,
    conversion: boolean,
    media?: Image | Video | Pano
  ): void;
}

export default function MenuButtons({
  tour,
  menuButtons,
  onMenuButtonClick,
}: MenuButtonsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(false);
  const windowSize = useWindowSize();
  const media = useAppSelector((s) => s.media);

  const carousel = tour.carousel;

  const carouselPills = useMemo(() => {
    const pills = [
      carousel.image.length > 0
        ? {
            id: 'imageCarousel',
            type: 'dialog' as MenuItemType,
            conversion: false,
            icon: 'image',
          }
        : null,
      carousel.video.length > 0
        ? {
            id: 'videoCarousel',
            type: 'dialog' as MenuItemType,
            icon: 'video',
            conversion: false,
          }
        : null,
    ];
    return pills.filter((pill) => pill !== null);
  }, [carousel]);

  const checkScrollability = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setCanScrollUp(scrollTop > 0);
      setCanScrollDown(scrollTop < scrollHeight - clientHeight - 1);
    }
  };

  const scroll = (direction: 'up' | 'down') => {
    if (containerRef.current) {
      const currentScroll = containerRef.current.scrollTop;
      const newScroll =
        direction === 'up'
          ? currentScroll - SCROLL_STEP
          : currentScroll + SCROLL_STEP;
      containerRef.current.scrollTo({ top: newScroll, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    checkScrollability();
  }, [windowSize?.height, menuButtons]);

  return (
    <div
      ref={containerRef}
      id={MENU_BUTTONS_ID}
      className={styles.container}
      onScroll={checkScrollability}
    >
      <div
        className={clsx(styles.arrow, styles.arrowUp, {
          [styles.arrowVisible]: canScrollUp,
        })}
        onClick={() => scroll('up')}
      >
        <Up />
      </div>
      {menuButtons
        .filter((menuButton) => menuButton.type !== 'fullscreen')
        .map((menuButton) => {
          return (
            <MenuButton
              icon={menuButton.icon}
              id={menuButton.id}
              key={menuButton.id}
              onClick={() =>
                onMenuButtonClick(
                  menuButton.id,
                  menuButton.type,
                  menuButton.conversion
                )
              }
            />
          );
        })}
      {carouselPills.length > 0
        ? carouselPills.map((pill) => {
            return (
              <MenuButton
                icon={pill!.icon}
                id={pill!.id}
                key={pill!.id}
                onClick={() => {
                  onMenuButtonClick(
                    pill!.id,
                    pill!.type,
                    pill!.conversion,
                    media
                  );
                }}
              />
            );
          })
        : null}
      <div
        className={clsx(styles.arrow, styles.arrowDown, {
          [styles.arrowVisible]: canScrollDown,
        })}
        onClick={() => scroll('down')}
      >
        <Down />
      </div>
    </div>
  );
}
