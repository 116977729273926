import { useState } from 'react';

import styles from './ControlPanel.module.scss';

import { Tour } from '../../../../../types';
import AutoRotate from '../../../../../components/AutoRotate/AutoRotate';
import Fullscreen from '../../../../../components/Fullscreen/Fullscreen';
import MenuButtons from './MenuButtons';
import Chat from './Chat';
import useMenuItemContent from '../../hooks/useMenuItemContent/useMenuItemContent';
import useTheme from '../../../../../hooks/useTheme/useTheme';
import useTourFunctionality from '../../../../../hooks/useTourFunctionality/useTourFunctionality';
import useIsMobile from '../../../../../hooks/useIsMobile/useIsMobile';

interface ControlPanelProps {
  tour: Tour;
}

export default function ControlPanel({ tour }: ControlPanelProps) {
  const theme = useTheme();
  const [autoRotateEnabled, setAutoRotateEnabled] = useState(tour.autoRotate);
  const { availableButtons } = useTourFunctionality(tour, theme);
  const isMobile = useIsMobile();

  const { openMenuItem } = useMenuItemContent();

  return (
    <div className={styles.container}>
      {availableButtons.filter((b) => b.type !== 'fullscreen').length > 0 && (
        <MenuButtons
          menuButtons={availableButtons}
          onMenuButtonClick={openMenuItem}
        />
      )}
      <Chat clientId={tour.client._id} themeId={tour.themeId} />
      {!isMobile && (
        <>
          <AutoRotate
            tour={tour}
            enabled={autoRotateEnabled}
            onChange={setAutoRotateEnabled}
          />
          <Fullscreen />
        </>
      )}
    </div>
  );
}
