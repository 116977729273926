import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { animated, useSpring, useTransition } from '@react-spring/web';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import styles from './Carousel.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';

import {
  Carousel as Config,
  CarouselItemType,
  Image as CarouselImage,
  Pano as CarouselPano,
  Video as CarouselVideo,
} from '../../../../types/carousel';
import PillGroup, {
  Colors,
  PillConfig,
} from '../../../../components/PillGroup/PillGroup';
import {
  showImage,
  showPano,
  showVideo,
  Image,
  Video,
} from '../../../../stores/slices/media';
import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';
import useViewer from '../../../../hooks/useViewer/useViewer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import {
  getCarouselVisible,
  setCarouselVisible,
} from '../../../../stores/slices/ui';
import ImageComponent from '../../../../components/Image/Image';
import onEnter from '../../../../utils/onEnter/onEnter';
import ScrollArrow from './ScrollArrow';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useHideUi from '../../../../hooks/useHideUi/useHideUi';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import {
  CAROUSEL_ID,
  TABS_ID,
  CAROUSEL_HAMBURGER_ID,
} from '../../../../constants/ids';

import { ReactComponent as Close } from './close.svg';
import { ReactComponent as FloorPlanIndicator } from './floorplan-indicator.svg';
import { ReactComponent as PanoIcon } from './3d.svg';
import { ReactComponent as PhotoIcon } from './photo.svg';
import { ReactComponent as VideoIcon } from './camera.svg';

interface CarouselItemProps {
  id: string;
  title: string;
  thumbnail: string;
  onClick(): void;
  active: boolean;
  hasFloorplan?: boolean;
}

const CarouselItem = React.memo(
  ({
    id,
    onClick,
    thumbnail,
    title,
    active,
    hasFloorplan,
  }: CarouselItemProps) => (
    <figure
      id={id}
      className={styles.item}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      data-cy="carousel-item"
      tabIndex={0}
    >
      {hasFloorplan && (
        <FloorPlanIndicator className={styles.floorPlanIndicator} />
      )}
      <ImageComponent src={thumbnail} alt="Carousel item" />
      {active && (
        <div
          className={styles.active}
          data-cy="carousel-active-item-indicator"
        />
      )}
      {title && (
        <figcaption className={styles.title}>
          <span title={title}>{title}</span>
        </figcaption>
      )}
    </figure>
  )
);

interface CarouselProps {
  availableTabs: CarouselItemType[];
  config: Config;
  floorPlanPanoIds: string[];
  pillColors: Colors;
  keepControlsOpenedBetweenTabs: boolean;
}

const LEFT_SCROLL_ARROW_CLASSNAME = 'carousel-left-scroll-arrow';
const RIGHT_SCROLL_ARROW_CLASSNAME = 'carousel-right-scroll-arrow';

export default function Carousel({
  availableTabs,
  config,
  floorPlanPanoIds,
  pillColors,
  keepControlsOpenedBetweenTabs,
}: CarouselProps) {
  const media = useAppSelector((state) => state.media);
  const windowSize = useWindowSize();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);
  const dispatch = useAppDispatch();
  const visible = useAppSelector(getCarouselVisible);
  const toggleVisible = () => dispatch(setCarouselVisible(!visible));
  const { panoId, viewer } = useViewer();
  const analyticsEvents = useAnalyticsEvents();
  const isMobile = useIsMobile();
  const isMobileLandscape = useIsMobile('landscape');
  const hideUi = useHideUi(keepControlsOpenedBetweenTabs);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());

      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: CarouselImage) => {
      if (image.image === (media as Image).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: CarouselVideo) => {
      if ((media as Video).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const carouselRef = useRef<HTMLDivElement>(null);
  const containerAnimation = useSpring({
    translateY:
      visible || !carouselRef.current
        ? 0
        : carouselRef.current?.clientHeight +
          (isMobile && !isMobileLandscape ? 0 : 10),
  });

  const pillsTransitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                display: <PanoIcon />,
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: <PhotoIcon />,
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: <VideoIcon />,
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  return (
    <animated.div
      style={containerAnimation}
      className={styles.container}
      data-cy="carousel"
      id={CAROUSEL_ID}
    >
      <div className={styles.controls}>
        {pillsTransitions(
          (style, item) =>
            item && (
              <animated.div style={style} className={styles.pills} id={TABS_ID}>
                <PillGroup<CarouselItemType>
                  key={pillGroupKey}
                  colors={pillColors}
                  pills={pills}
                  value={media.type}
                  pillVariant="pro"
                  onChange={(newItem) => {
                    if (newItem === media.type) return;
                    switch (newItem) {
                      case 'pano':
                        const [firstPano] = config.pano;
                        carouselRef?.current?.scroll(0, 0);
                        setPano(firstPano);
                        break;
                      case 'image':
                        const [firstImage] = config.image;
                        carouselRef?.current?.scroll(0, 0);
                        setImage(firstImage);
                        hideUi();
                        break;
                      case 'video':
                        const [firstVideo] = config.video;
                        carouselRef?.current?.scroll(0, 0);
                        setVideo(firstVideo);
                        hideUi();
                        break;
                    }
                  }}
                />
              </animated.div>
            )
        )}
        <div
          className={styles.close}
          role="button"
          onClick={toggleVisible}
          data-cy="carousel-hamburger"
          id={CAROUSEL_HAMBURGER_ID}
        >
          <Close className={!visible ? styles.showArrow : ''} />
        </div>
      </div>

      <div
        className={styles.arrows}
        style={{
          height: carouselRef.current?.clientHeight,
        }}
      >
        <ScrollArrow
          className={LEFT_SCROLL_ARROW_CLASSNAME}
          selectedType={media.type}
          direction="left"
          show={showLeft}
        />
        <ScrollArrow
          className={RIGHT_SCROLL_ARROW_CLASSNAME}
          selectedType={media.type}
          direction="right"
          show={showRight}
        />
      </div>

      <Swiper
        className={styles.carousel}
        ref={carouselRef as unknown as React.RefObject<SwiperRef>}
        modules={[Navigation]}
        id="carousel"
        slidesOffsetAfter={20}
        slidesPerView="auto"
        navigation={{
          enabled: true,
          nextEl: `.${RIGHT_SCROLL_ARROW_CLASSNAME}`,
          prevEl: `.${LEFT_SCROLL_ARROW_CLASSNAME}`,
        }}
        spaceBetween={26}
        onReachEnd={() => {
          setShowRight(false);
        }}
        onReachBeginning={() => {
          setShowLeft(false);
        }}
        onFromEdge={() => {
          setShowLeft(true);
          setShowRight(true);
        }}
        onResize={(swiper: any) => {
          const shouldShowRight = swiper.wrapperEl.scrollWidth > swiper.width;
          setShowRight(shouldShowRight);
        }}
      >
        {media.type === 'pano' &&
          config.pano
            .filter((pano) => pano.carousel)
            .map((pano) => (
              <SwiperSlide key={pano.panoid} className={styles.slide}>
                <CarouselItem
                  active={media.type === 'pano' && panoId === pano.panoid}
                  title={pano.title}
                  thumbnail={pano.thumbnail.url}
                  onClick={() => setPano(pano)}
                  id={pano.panoid}
                  hasFloorplan={floorPlanPanoIds.includes(pano.panoid)}
                />
              </SwiperSlide>
            ))}
        {media.type === 'image' &&
          config.image.map((image) => (
            <SwiperSlide key={image.imageId} className={styles.slide}>
              <CarouselItem
                id={image.imageId}
                active={(media as Image).source === image.image}
                title={image.title}
                thumbnail={image.thumbnail.url}
                onClick={() => setImage(image)}
              />
            </SwiperSlide>
          ))}
        {media.type === 'video' &&
          config.video.map((video) => (
            <SwiperSlide key={video.videoId} className={styles.slide}>
              <CarouselItem
                id={video.videoId}
                active={(media as Video).source === video}
                title={video.title}
                thumbnail={
                  video.type === 'tourbuilder'
                    ? video.thumbnail.url
                    : video.thumb
                }
                onClick={() => setVideo(video)}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </animated.div>
  );
}
