import React from 'react';

import { GOOGLE_MAPS_DIRECTIONS_URL } from '../../../../../../../constants';
import { Place } from '../../../../../../../types/place';
import { Theme } from '../../../../../../../types/theme';
import { Tour } from '../../../../../../../types';
import { useGetNearbyPlacesQuery } from '../../../../../../../api/places';
import getDistance from '../../../../../../../utils/getDistance/getDistance';
import Map from '../../../../../../../components/Map/Map';
import useAnalyticsEvents from '../../../../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useNearbyPlacesByType from '../../../useNearbyPlacesByType/useNearbyPlacesByType';

import { ReactComponent as School } from '../../assets/school.svg';
import { ReactComponent as Restaurant } from '../../assets/restaurant.svg';
import { ReactComponent as Shopping } from '../../assets/shopping.svg';

import styles from './MapMenuContent.module.scss';

interface MapMenuContentProps {
  tour: Tour;
  theme: Theme;
  onClose?: () => void;
}

interface NearbySectionProps {
  tourLatitude: number;
  tourLongitude: number;
  title: string;
  icon: React.ReactNode;
  places: Place[];
}

const KM_TO_MILE_EQUIVALENT = 0.6214;

const NearbySection = ({
  tourLatitude,
  tourLongitude,
  title,
  icon,
  places,
}: NearbySectionProps) => {
  if (!places.length) return null;

  return (
    <div className={styles.nearbySection}>
      <div className={styles.nearbySectionTitle}>
        {icon}
        {title}
      </div>
      {places.map((place) => {
        return (
          <div key={place.name} className={styles.nearbyPlace}>
            <div className={styles.nearbyPlaceName}>{place.name}</div>
            {place.geometry ? (
              <div key={place.name} className={styles.nearbyPlaceDistance}>
                {(
                  getDistance(
                    tourLatitude,
                    tourLongitude,
                    place.geometry.location.lat,
                    place.geometry.location.lng
                  ) * KM_TO_MILE_EQUIVALENT
                ).toFixed(2)}{' '}
                mile
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default function MapMenuContent({
  tour,
  theme,
  onClose,
}: MapMenuContentProps) {
  const [longitude, latitude] = tour.loc;
  const { data: nearbyPlaces } = useGetNearbyPlacesQuery({
    longitude,
    latitude,
  });
  const locationAddress = tour.info.address;

  const restaurantPlaces = useNearbyPlacesByType(nearbyPlaces?.food);
  const schoolPlaces = useNearbyPlacesByType(nearbyPlaces?.school);
  const shoppingPlaces = useNearbyPlacesByType(nearbyPlaces?.shopping);

  const isNearbyPlacesVisible =
    restaurantPlaces.length || schoolPlaces.length || shoppingPlaces.length;

  const directionsLinkEncoded = `${GOOGLE_MAPS_DIRECTIONS_URL}${encodeURIComponent(
    `${tour.info.address.name} ${tour.info.address.streetAddress} ${tour.info.address.city} ${tour.info.address.postalCode}`
  )}`;
  const analyticsEvents = useAnalyticsEvents();

  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <div className={styles.title}>{tour.title}</div>
        {locationAddress ? (
          <div className={styles.address}>
            {locationAddress.name} {locationAddress.city},{' '}
            {locationAddress.state} {locationAddress.postalCode}
          </div>
        ) : null}
      </div>
      <div className={styles.mapBar}>
        <div>
          <Map
            tour={tour}
            theme={theme}
            className={styles.map}
            onClose={onClose}
          />
        </div>
        <div className={styles.actions}>
          {tour.contact.directions.show ? (
            <a
              className={styles.action}
              href={directionsLinkEncoded}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                analyticsEvents.conversion('directions', 'Directions');
              }}
            >
              Get directions
            </a>
          ) : null}
        </div>
      </div>
      {isNearbyPlacesVisible ? (
        <div className={styles.nearby}>
          <div className={styles.nearbyTitle}>Nearby</div>

          <NearbySection
            tourLatitude={latitude}
            tourLongitude={longitude}
            title="Restaurants"
            icon={<Restaurant />}
            places={restaurantPlaces}
          />
          <NearbySection
            tourLatitude={latitude}
            tourLongitude={longitude}
            title="Schools"
            icon={<School />}
            places={schoolPlaces}
          />
          <NearbySection
            tourLatitude={latitude}
            tourLongitude={longitude}
            title="Shopping"
            icon={<Shopping />}
            places={shoppingPlaces}
          />
        </div>
      ) : null}
    </div>
  );
}
