import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';

import {
  CarouselItemType,
  Carousel as Config,
  Pano as CarouselPano,
  Image as CarouselImage,
  Video as CarouselVideo,
} from '../../../../../types/carousel';

import onEnter from '../../../../../utils/onEnter/onEnter';
import useAnalyticsEvents from '../../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import useViewer from '../../../../../hooks/useViewer/useViewer';
import useWindowSize from '../../../../../hooks/useWindowSize/useWindowSize';
import {
  showPano,
  showImage,
  showVideo,
  Image,
  Video,
} from '../../../../../stores/slices/media';

import PillGroup, {
  Colors,
  PillConfig,
} from '../../../../../components/PillGroup/PillGroup';
import { ReactComponent as PanoIcon } from './assets/3d.svg';
import { ReactComponent as PhotoIcon } from './assets/photo.svg';
import { ReactComponent as VideoIcon } from './assets/camera.svg';

import styles from './CarouselMobile.module.scss';

interface CarouselItemProps {
  id: string;
  title: string;
  thumbnail: string;
  onClick(): void;
  active: boolean;
}

const CarouselItem = React.memo(
  ({ id, onClick, thumbnail, title, active }: CarouselItemProps) => (
    <figure
      id={id}
      className={clsx(styles.item, active && styles.active)}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      data-cy="carousel-item"
      tabIndex={0}
    >
      {title && (
        <figcaption className={styles.title}>
          <span title={title}>{title}</span>
        </figcaption>
      )}
    </figure>
  )
);

interface CarouselProps {
  availableTabs: CarouselItemType[];
  config: Config;
  pillColors: Colors;
}

export default function CarouselMobile({
  availableTabs,
  config,
  pillColors,
}: CarouselProps) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const media = useAppSelector((state) => state.media);
  const { panoId, viewer } = useViewer();
  const dispatch = useAppDispatch();
  const analyticsEvents = useAnalyticsEvents();
  const windowSize = useWindowSize();
  const [pillGroupKey, setPillGroupKey] = useState<number>(0);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());

      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const setImage = useCallback(
    (image: CarouselImage) => {
      if (image.image === (media as Image).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: CarouselVideo) => {
      if ((media as Video).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  // Mount and unmount active pill group to recalculate its dimensions on window resize
  useEffect(() => setPillGroupKey((key) => key + 1), [windowSize]);

  const pills: PillConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                display: <PanoIcon />,
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                display: <PhotoIcon />,
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                display: <VideoIcon />,
              },
            ]
          : []),
      ].filter((pill) => availableTabs.includes(pill.key)),
    [availableTabs, config]
  );

  return (
    <div className={styles.container}>
      <div className={styles.pillContainer}>
        <PillGroup<CarouselItemType>
          key={pillGroupKey}
          colors={pillColors}
          pills={pills}
          value={media.type}
          pillVariant="pro"
          onChange={(newItem) => {
            if (newItem === media.type) return;
            switch (newItem) {
              case 'pano':
                const [firstPano] = config.pano;
                carouselRef?.current?.scroll(0, 0);
                setPano(firstPano);
                break;
              case 'image':
                const [firstImage] = config.image;
                carouselRef?.current?.scroll(0, 0);
                setImage(firstImage);
                break;
              case 'video':
                const [firstVideo] = config.video;
                carouselRef?.current?.scroll(0, 0);
                setVideo(firstVideo);
                break;
            }
          }}
        />
      </div>
      <Swiper
        className={styles.carousel}
        ref={carouselRef as unknown as React.RefObject<SwiperRef>}
        modules={[Mousewheel]}
        id="carousel"
        direction={'vertical'}
        mousewheel={true}
        spaceBetween={0}
        slidesPerView={6}
      >
        {media.type === 'pano' &&
          config.pano
            .filter((pano) => pano.carousel)
            .map((pano) => (
              <SwiperSlide key={pano.panoid}>
                <CarouselItem
                  active={media.type === 'pano' && panoId === pano.panoid}
                  title={pano.title}
                  thumbnail={pano.thumbnail.url}
                  onClick={() => setPano(pano)}
                  id={pano.panoid}
                />
              </SwiperSlide>
            ))}
        {media.type === 'image' &&
          config.image.map((image) => (
            <SwiperSlide key={image.imageId}>
              <CarouselItem
                id={image.imageId}
                active={(media as Image).source === image.image}
                title={image.title}
                thumbnail={image.thumbnail.url}
                onClick={() => setImage(image)}
              />
            </SwiperSlide>
          ))}
        {media.type === 'video' &&
          config.video.map((video) => (
            <SwiperSlide key={video.videoId}>
              <CarouselItem
                id={video.videoId}
                active={(media as Video).source === video}
                title={video.title}
                thumbnail={
                  video.type === 'tourbuilder'
                    ? video.thumbnail.url
                    : video.thumb
                }
                onClick={() => setVideo(video)}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
