import { useMemo } from 'react';

import { Tour } from '../../../../../types';
import useTheme from '../../../../../hooks/useTheme/useTheme';
import useTourFunctionality from '../../../../../hooks/useTourFunctionality/useTourFunctionality';
import useWindowSize from '../../../../../hooks/useWindowSize/useWindowSize';

const MENU_BUTTON_HEIGHT = 55;
const TOUR_CONTROLS_HEIGHT = 430; // Zoom, Autorotate, Fullscreen combined
const SWITCH_TO_LANDSCAPE_THRESHOLD = 50;

export default function useIsViewportTooLow(
  tour: Tour,
  includeTourControls: boolean = true
) {
  const theme = useTheme();
  const { availableButtons } = useTourFunctionality(tour, theme);
  const windowSize = useWindowSize();

  return useMemo(() => {
    const menuHeight =
      MENU_BUTTON_HEIGHT *
      availableButtons
        .filter((menuButton) => menuButton.type !== 'fullscreen')
        .filter((button) => button.show).length;
    const additionalControlsHeight =
      (includeTourControls ? TOUR_CONTROLS_HEIGHT : 100) +
      SWITCH_TO_LANDSCAPE_THRESHOLD;
    const neededHeight = menuHeight + additionalControlsHeight;
    return {
      isViewportTooLow:
        neededHeight > (windowSize?.height || document.body.clientHeight),
      menuHeight,
      neededHeight,
      additionalControlsHeight,
    };
  }, [availableButtons, windowSize?.height, includeTourControls]);
}
