import { CSSProperties, useEffect, useState, useMemo } from 'react';

import useURLParam, {
  OverlayURLParam,
} from '../../hooks/useURLParam/useURLParam';
import { MenuItemContentProvider } from './hooks/useMenuItemContent/useMenuItemContent';
import { setControlsVisible } from '../../stores/slices/ui';
import { ThemeProps } from '../Theme';
import { useAppDispatch } from '../../hooks/redux';
import { useAppSelector } from '../../hooks/redux';
import { useNavigateToPhoto } from '../../hooks/useNavigateToPhoto/useNavigateToPhoto';
import { useNavigateToVideo } from '../../hooks/useNavigateToVideo/useNavigateToVideo';
import Carousel from './components/Carousel/Carousel';
import CloseMedia from '../../components/CloseMedia/CloseMedia';
import FloorPlan from '../../components/FloorPlan/FloorPlan';
import FloorPlanMobileControls from './components/FloorPlanMobileControls/FloorPlanMobileControls';
import GlobalCss from './GlobalCss';
import MediaImage from '../../components/MediaImage/MediaImage';
import MediaImageControls from '../../components/MediaImageControls/MediaImageControls';
import MediaVideo from '../../components/MediaVideo/MediaVideo';
import Pano from './components/Pano/Pano';
import ShareLive from '../../components/ShareLive/ShareLive';
import StartScreen from '../../components/StartScreen/StartScreen';
import useIsMobile from '../../hooks/useIsMobile/useIsMobile';
import useVerticalLayout from './hooks/useVerticalLayout/useVerticalLayout';
import useTourFunctionality from '../../hooks/useTourFunctionality/useTourFunctionality';
import useFireInitialAnalyticsEvents from '../../hooks/useFireInitialAnalyticsEvents/useFireInitialAnalyticsEvents';

const CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT = [
  '56c3a81809a3adea4c46c794',
  '6490e2d6f6a1ae70fc12c850',
];

const CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT_ON_DESKTOP = [
  '5eed1e0243b4eb1489d7a44e',
];

export default function TourBuilderProTheme({
  cssVariables,
  theme,
  tour,
}: ThemeProps) {
  const disableStartScreen =
    useURLParam(OverlayURLParam.START_SCREEN) === 'false';
  const dispatch = useAppDispatch();
  const [showStartScreen, setShowStartScreen] = useState(
    tour.startScreen.show && !disableStartScreen
  );
  const { availableTabs, logo, isCarouselEnabled } = useTourFunctionality(
    tour,
    theme
  );
  const hideRightMenu = useURLParam(OverlayURLParam.HIDE_RIGHT_MENU) === 'true';
  const hideShareLive = useURLParam(OverlayURLParam.HIDE_SHARE_LIVE) === 'true';
  const media = useAppSelector((s) => s.media);

  const isMobile = useIsMobile();
  const isLandscape = useIsMobile('landscape');
  const { isViewportTooLow } = useVerticalLayout(tour);

  const fireInitialAnalyticsEvents = useFireInitialAnalyticsEvents();

  useEffect(() => {
    if (!showStartScreen) {
      fireInitialAnalyticsEvents();
    }
  }, [fireInitialAnalyticsEvents, showStartScreen]);

  useEffect(() => {
    if (tour.tourType !== 'property') {
      return;
    }

    if (
      CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT.includes(tour.client._id) ||
      (CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT_ON_DESKTOP.includes(
        tour.client._id
      ) &&
        !isMobile)
    ) {
      dispatch(setControlsVisible(true));
    }
  }, [dispatch, isMobile, tour.client._id, tour.tourType]);

  const { navigateToPhoto: navigateToPhotoLeft } = useNavigateToPhoto(
    'left',
    tour.carousel.image
  );
  const { navigateToPhoto: navigateToPhotoRight } = useNavigateToPhoto(
    'right',
    tour.carousel.image
  );
  const { navigateToVideo: navigateToVideoLeft } = useNavigateToVideo(
    'left',
    tour.carousel.video
  );
  const { navigateToVideo: navigateToVideoRight } = useNavigateToVideo(
    'right',
    tour.carousel.video
  );

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <>
      <GlobalCss />
      <div
        data-cy="tb-default-theme"
        id="theme"
        style={
          {
            '--button-border-radius': '10px',
            '--button-border-radius-small': '5px',
            '--controls-border-radius': '50px',
          } as CSSProperties
        }
      >
        <MenuItemContentProvider>
          {media.type === 'pano' && (
            <FloorPlan
              configs={tour.floorplan}
              sliders={tour.sliders}
              additionalControls={
                isMobile || isViewportTooLow ? (
                  <FloorPlanMobileControls
                    theme={theme}
                    tour={tour}
                    isLandscape={isLandscape}
                  />
                ) : null
              }
            />
          )}
          {media.type === 'pano' && (
            <Pano hideRightMenu={hideRightMenu} tour={tour} theme={theme} />
          )}
          {media.type === 'image' && (
            <>
              <MediaImage
                key={media.source.url}
                config={media}
                showArrows={tour.carousel.image.length > 1}
                arrowVariant="tb-pro"
                onNavigateLeft={navigateToPhotoLeft}
                onNavigateRight={navigateToPhotoRight}
                cssVariables={cssVariables}
              />
              <MediaImageControls tour={tour} />
            </>
          )}
          {media.type === 'video' && (
            <MediaVideo
              arrowVariant="tb-pro"
              cssVariables={cssVariables}
              onNavigateLeft={navigateToVideoLeft}
              onNavigateRight={navigateToVideoRight}
              showArrows={tour.carousel.video.length > 1}
              source={media.source}
              tourId={tour._id}
            />
          )}
          {media.type !== 'pano' && (
            <CloseMedia homePano={tour.carousel.pano[0]} />
          )}
          {isCarouselEnabled && (
            <Carousel
              availableTabs={availableTabs}
              config={tour.carousel}
              pillColors={{
                default: theme.styles.carousel.tabs.icons.default.color,
                active: theme.styles.carousel.tabs.icons.active.color,
              }}
              floorPlanPanoIds={floorPlanPanoIds}
              keepControlsOpenedBetweenTabs={CLIENTS_WITH_VIEW_CONTROLS_OPENED_BY_DEFAULT.includes(
                tour.client._id
              )}
            />
          )}
          {showStartScreen && (
            <StartScreen
              config={tour.startScreen}
              onStart={() => {
                fireInitialAnalyticsEvents();
                setShowStartScreen(false);
              }}
              logo={logo}
              poweredBy={tour.poweredByLCP360}
              showAccessibilityStatement={true}
            />
          )}
          {!hideShareLive && (
            <ShareLive
              clientId={tour.client._id}
              tourId={tour._id}
              themeId={tour.themeId}
            />
          )}
        </MenuItemContentProvider>
      </div>
    </>
  );
}
