import clsx from 'clsx';
import { useRef } from 'react';
import DOMPurify from 'dompurify';

import { Theme } from '../../../../../../types/theme';
import { Tour } from '../../../../../../types';
import onEnter from '../../../../../../utils/onEnter/onEnter';

import styles from './InfoModal.module.scss';

interface InfoModalProps {
  onClose: () => void;
  tour: Tour;
  theme: Theme;
}

export default function InfoModal({ onClose, tour, theme }: InfoModalProps) {
  const ref = useRef<HTMLDivElement>(null);

  const website = tour.info?.website || '';

  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoBackground} />
      <div className={styles.infoContent} ref={ref}>
        <i
          aria-label="Close modal"
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
          className={clsx(styles.close, 'fa fa-times active')}
        ></i>
        <div className={styles.leftContent}>
          {theme.mainLogo?.url ? (
            <img src={theme.mainLogo.url} alt="logo" />
          ) : null}
          <div>{tour.info.address.streetAddress}</div>
          <div>
            {tour.info.address.city +
              ', ' +
              tour.info.address.state +
              ' ' +
              tour.info.address.postalCode}
          </div>
          <div>{new URL(website).host}</div>
          <div>{tour.info.phone}</div>
        </div>
        <div className={styles.rightContent}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tour.info.description || ''),
            }}
          />
        </div>
      </div>
    </div>
  );
}
