import { useState } from 'react';

import { ReactComponent as Facebook } from '../../../../../../../assets/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../../../../assets/linkedin.svg';
import { ReactComponent as Twitter } from '../../../../../../../assets/twitter.svg';
import { ReactComponent as ShareIcon } from '../../assets/share.svg';

import { Tour } from '../../../../../../../types';
import { useClipboard } from '../../../../../../../hooks/useClipboard/useClipboard';
import useIsMobile from '../../../../../../../hooks/useIsMobile/useIsMobile';
import useShareLinks from '../../../../../../../hooks/useShareLinks/useShareLinks';

import styles from './ShareMenuContent.module.scss';

interface ShareMenuContentProps {
  tour: Tour;
}

export default function ShareMenuContent({ tour }: ShareMenuContentProps) {
  const [shareLinkMessage, setShareLinkMessage] = useState('Copy');
  const { encodedShareLink, shareLink } = useShareLinks(tour);
  const { isClipboardEnabled, writeTextToClipboard } = useClipboard();
  const isMobile = useIsMobile();

  const copyToClipboard = () => {
    writeTextToClipboard?.(shareLink).then(() => {
      setShareLinkMessage('Copied');

      setTimeout(() => {
        setShareLinkMessage('Copy');
      }, 3000);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.unitName}>{tour.title}</div>
      <div className={styles.message}>Share this tour...</div>
      <div className={styles.icons}>
        {tour.share.button.facebook ? (
          <div className={styles.linkContainer}>
            <span className={styles.linkLabel}>Facebook</span>
            <a
              aria-label="Share tour on Facebook"
              className={styles.socialMediaButton}
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
          </div>
        ) : null}
        {tour.share.button.linkedIn ? (
          <div className={styles.linkContainer}>
            <span className={styles.linkLabel}>LinkedIn</span>
            <a
              aria-label="Share tour on LinkedIn"
              className={styles.socialMediaButton}
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin />
            </a>
          </div>
        ) : null}
        {tour.share.button.twitter ? (
          <div className={styles.linkContainer}>
            <span className={styles.linkLabel}>Twitter</span>
            <a
              aria-label="Share tour on Twitter"
              className={styles.socialMediaButton}
              href={`https://twitter.com/intent/tweet?url=${encodedShareLink}`}
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
            </a>
          </div>
        ) : null}
      </div>
      {isMobile ? (
        <div className={styles.mobileButton}>
          {isClipboardEnabled && (
            <button
              className={styles.button}
              onClick={copyToClipboard}
              title={shareLinkMessage}
            >
              {shareLinkMessage}
            </button>
          )}
        </div>
      ) : (
        <div className={styles.copyLink}>
          <div className={styles.shareIcon}>
            <ShareIcon />
          </div>
          <div className={styles.shareLink}>{shareLink}</div>
          {isClipboardEnabled && (
            <button
              className={styles.button}
              onClick={copyToClipboard}
              title={shareLinkMessage}
            >
              {shareLinkMessage}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
