import React, { CSSProperties } from 'react';
import { Bounce } from 'react-activity';
import { useSwipeable } from 'react-swipeable';

import ImageComponent from '../../../../../../../components/Image/Image';
import MediaArrow from '../../../../../../../components/MediaArrow/MediaArrow';

import styles from './ImageContent.module.scss';
import useIsMobile from '../../../../../../../hooks/useIsMobile/useIsMobile';

interface ImageContentProps {
  config: { source: string; idx: number };
  cssVariables?: CSSProperties;
  showArrows: boolean;
  onNavigationLeft?: () => void;
  onNavigationRight?: () => void;
}

export default function ImageContent({
  config: { source },
  cssVariables = {},
  showArrows,
  onNavigationLeft,
  onNavigationRight,
}: ImageContentProps) {
  const isMobile = useIsMobile();

  const swipeHandlers = useSwipeable({
    onSwipedRight: isMobile && onNavigationLeft ? onNavigationLeft : undefined,
    onSwipedLeft: isMobile && onNavigationRight ? onNavigationRight : undefined,
  });
  return (
    <div className={styles.container} {...swipeHandlers} style={cssVariables}>
      {onNavigationLeft && showArrows ? (
        <MediaArrow
          direction="left"
          arrowVariant={isMobile ? 'side-mobile' : 'side'}
          onNavigate={onNavigationLeft}
        />
      ) : null}
      <div className={styles.loading}>
        <Bounce
          className={styles.bounce}
          color="#727981"
          size={32}
          speed={1}
          animating={true}
        />
      </div>
      <ImageComponent
        animationDelay={200}
        alt="currently selected carousel item"
        className={styles.image}
        src={source}
      />
      {onNavigationRight && showArrows ? (
        <MediaArrow
          direction="right"
          arrowVariant={isMobile ? 'side-mobile' : 'side'}
          onNavigate={onNavigationRight}
        />
      ) : null}
    </div>
  );
}
