import clsx from 'clsx';
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';

import styles from './Carousel.module.scss';

import { Swiper, SwiperSlide, SwiperRef, SwiperClass } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import {
  Carousel as Config,
  CarouselItemType,
  Pano as CarouselPano,
  Image as CarouselImage,
  Video as CarouselVideo,
} from '../../../../types/carousel';
import useViewer from '../../../../hooks/useViewer/useViewer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { animated, useSpring, useTransition } from '@react-spring/web';
import ImageComponent from '../../../../components/Image/Image';
import ViewControls from '../ViewControls/ViewControls';
import ScrollArrow from './ScrollArrow';
import {
  getAccessibilityMode,
  getCarouselVisible,
} from '../../../../stores/slices/ui';
import onEnter from '../../../../utils/onEnter/onEnter';
import hexToRGB, { HEX } from '../../../../utils/hexToRGB/hexToRGB';
import TabGroup, { Colors, TabConfig } from './TabGroup';
import useAnalyticsEvents from '../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import {
  showImage,
  showPano,
  showVideo,
  Image,
  Video,
} from '../../../../stores/slices/media';
import { Tour } from '../../../../types';
import useHideUi from '../../../../hooks/useHideUi/useHideUi';
import { ReactComponent as FloorPlanIndicator } from './floorplan-indicator.svg';
import { CAROUSEL_ID } from '../../../../constants/ids';

interface CarouselItemProps {
  title?: string;
  thumbnail: string;
  onClick(): void;
  active: boolean;
  accessibility: boolean;
  hasFloorPlan?: boolean;
}
const CarouselItem = React.memo(
  ({
    onClick,
    thumbnail,
    title,
    active,
    accessibility,
    hasFloorPlan,
  }: CarouselItemProps) => (
    <figure
      className={styles.item}
      onClick={onClick}
      onKeyDown={onEnter(onClick)}
      data-cy="carousel-item"
      tabIndex={0}
    >
      {hasFloorPlan && (
        <FloorPlanIndicator className={styles.floorPlanIndicator} />
      )}
      <ImageComponent src={thumbnail} alt="Carousel item" />
      {active && (
        <div
          className={clsx(styles.active, {
            [styles.accessibilityActive]: accessibility,
          })}
          data-cy="carousel-active-item-indicator"
        />
      )}
      {title && (
        <figcaption className={styles.title}>
          <span title={title}>{title}</span>
        </figcaption>
      )}
    </figure>
  )
);

interface CarouselProps {
  availableTabs: CarouselItemType[];
  config: Config;
  tabColors: Colors;
  tour: Tour;
}

const LEFT_SCROLL_ARROW_CLASSNAME = 'carousel-left-scroll-arrow';
const RIGHT_SCROLL_ARROW_CLASSNAME = 'carousel-right-scroll-arrow';

export default function Carousel({
  availableTabs,
  config,
  tabColors,
  tour,
}: CarouselProps) {
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const visible = useAppSelector(getCarouselVisible);
  const { panoId, viewer } = useViewer();
  const media = useAppSelector((state) => state.media);
  const accessibilityModeEnabled = useAppSelector(getAccessibilityMode);
  const [tabGroupKey, setTabGroupKey] = useState<number>(0);
  const analyticsEvents = useAnalyticsEvents();
  const hideUi = useHideUi();

  // Mount and unmount active tab group to recalculate its dimensions on window resize
  useEffect(() => setTabGroupKey((key) => key + 1), [windowSize]);

  const setPano = useCallback(
    (pano: CarouselPano) => {
      dispatch(showPano());
      if (panoId !== pano.panoid) {
        viewer?.setPano(pano.panoid, { pov: pano.pov });
      }
    },
    [dispatch, panoId, viewer]
  );

  const carouselRef = useRef<HTMLDivElement>(null);

  const containerAnimation = useSpring({
    translateY: visible ? 0 : carouselRef.current?.clientHeight,
  });

  const tabsTransitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const setImage = useCallback(
    (image: CarouselImage) => {
      if (image.image === (media as Image).source) {
        return;
      }

      analyticsEvents.imageGalleryView(
        image.imageId,
        image.image.url,
        image.title
      );

      dispatch(
        showImage({
          imageId: image.imageId,
          imageTitle: image.title,
          source: image.image,
        })
      );
    },
    [analyticsEvents, dispatch, media]
  );

  const setVideo = useCallback(
    (video: CarouselVideo) => {
      if ((media as Video).source === video) {
        return;
      }

      analyticsEvents.videoGalleryView(video.videoId, video.title);

      dispatch(showVideo(video));
    },
    [analyticsEvents, dispatch, media]
  );

  const tabs: TabConfig<CarouselItemType>[] = useMemo(
    () =>
      [
        ...(config.pano.length > 0
          ? [
              {
                key: 'pano' as CarouselItemType,
                icon: 'icon-pano',
                label: 'Tours',
              },
            ]
          : []),
        ...(config.image.length > 0
          ? [
              {
                key: 'image' as CarouselItemType,
                icon: 'icon-image',
                label: 'Images',
              },
            ]
          : []),
        ...(config.video.length > 0
          ? [
              {
                key: 'video' as CarouselItemType,
                icon: 'icon-video',
                label: 'Videos',
              },
            ]
          : []),
      ].filter((tab) => availableTabs.includes(tab.key)),
    [availableTabs, config]
  );

  const floorPlanPanoIds = useMemo(() => {
    return tour.floorplan.flatMap((floorplan) =>
      floorplan.hotspots.map((hotspot) => hotspot.panoid)
    );
  }, [tour.floorplan]);

  return (
    <animated.div
      style={containerAnimation}
      className={styles.container}
      data-cy="carousel"
      id={CAROUSEL_ID}
    >
      <div className={styles.controls}>
        {tabsTransitions(
          (style, item) =>
            item && (
              <animated.div style={style} className={styles.row}>
                <TabGroup<CarouselItemType>
                  key={tabGroupKey}
                  colors={tabColors}
                  tabs={tabs}
                  value={media.type}
                  onChange={(newItem) => {
                    if (newItem === media.type) return;
                    switch (newItem) {
                      case 'pano':
                        const [firstPano] = config.pano;
                        carouselRef?.current?.scroll(0, 0);
                        setPano(firstPano);
                        break;
                      case 'image':
                        const [firstImage] = config.image;
                        carouselRef?.current?.scroll(0, 0);
                        setImage(firstImage);
                        hideUi();
                        break;
                      case 'video':
                        const [firstVideo] = config.video;
                        carouselRef?.current?.scroll(0, 0);
                        setVideo(firstVideo);
                        hideUi();
                        break;
                    }
                  }}
                />
                {media.type === 'pano' && <ViewControls tour={tour} />}
              </animated.div>
            )
        )}
      </div>
      <div
        className={styles.arrows}
        style={{
          height: carouselRef.current?.clientHeight,
        }}
      >
        {!accessibilityModeEnabled ? (
          <>
            <ScrollArrow
              className={LEFT_SCROLL_ARROW_CLASSNAME}
              direction="left"
              accessibility={accessibilityModeEnabled}
              show={showLeft}
            />

            <ScrollArrow
              className={RIGHT_SCROLL_ARROW_CLASSNAME}
              direction="right"
              accessibility={accessibilityModeEnabled}
              show={showRight}
            />
          </>
        ) : null}
      </div>
      <Swiper
        className={styles.carousel}
        ref={carouselRef as unknown as React.RefObject<SwiperRef>}
        style={{
          backgroundColor: hexToRGB(tabColors.background as HEX, 0.85),
        }}
        modules={[Navigation]}
        slidesPerView="auto"
        navigation={{
          enabled: true,
          nextEl: `.${RIGHT_SCROLL_ARROW_CLASSNAME}`,
          prevEl: `.${LEFT_SCROLL_ARROW_CLASSNAME}`,
        }}
        spaceBetween={5}
        onReachEnd={() => {
          setShowRight(false);
        }}
        onReachBeginning={() => {
          setShowLeft(false);
        }}
        onFromEdge={() => {
          setShowLeft(true);
          setShowRight(true);
        }}
        onResize={(swiper: SwiperClass): void => {
          const shouldShowRight = swiper.wrapperEl.scrollWidth > swiper.width;
          setShowRight(shouldShowRight);
        }}
      >
        {media.type === 'pano' &&
          config.pano
            .filter((pano) => pano.carousel)
            .map((pano) => (
              <SwiperSlide key={pano.panoid} className={styles.slide}>
                <CarouselItem
                  accessibility={accessibilityModeEnabled}
                  active={media.type === 'pano' && panoId === pano.panoid}
                  title={pano.title}
                  thumbnail={pano.thumbnail.url}
                  onClick={() => setPano(pano)}
                  hasFloorPlan={floorPlanPanoIds.includes(pano.panoid)}
                />
              </SwiperSlide>
            ))}
        {media.type === 'image' &&
          config.image.map((image) => (
            <SwiperSlide key={image.imageId} className={styles.slide}>
              <CarouselItem
                accessibility={accessibilityModeEnabled}
                active={(media as Image).source === image.image}
                title={image.title}
                thumbnail={image.thumbnail.url}
                onClick={() => setImage(image)}
              />
            </SwiperSlide>
          ))}
        {media.type === 'video' &&
          config.video.map((video) => (
            <SwiperSlide key={video.videoId} className={styles.slide}>
              <CarouselItem
                accessibility={accessibilityModeEnabled}
                active={(media as Video).source === video}
                title={video.title}
                thumbnail={
                  video.type === 'tourbuilder'
                    ? video.thumbnail.url
                    : video.thumb
                }
                onClick={() => setVideo(video)}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </animated.div>
  );
}
