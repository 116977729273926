import { CSSProperties } from 'react';

import {
  RoundedLivcorTheme,
  SquareLivcorTheme,
} from './LivcorTheme/LivcorTheme';
import { Tour } from '../types';
import { Theme as ITheme } from '../types/theme';
import LegacyTheme from './LegacyTheme/LegacyTheme';
import TourBuilderProTheme from './TourBuilderProTheme/TourBuilderProTheme';
import TourBuilderUnitTheme from './TourBuilderUnitTheme/TourBuilderUnitTheme';
import useURLParam, { OverlayURLParam } from '../hooks/useURLParam/useURLParam';
import GreystarTheme from './Greystar/Standard/GreystarTheme';
import GreystarSimpleTheme from './Greystar/Simple/GreystarSimpleTheme';
import GreystarUnitTheme from './Greystar/Unit/GreystarUnitTheme';

const THEMES_CONFIGURATION = {
  v1: TourBuilderUnitTheme,
  'livcor-rounded': RoundedLivcorTheme,
  'livcor-square': SquareLivcorTheme,
  'tb-default': TourBuilderProTheme,
  'tb-unit-default': TourBuilderUnitTheme,
  'tb-legacy': LegacyTheme,
  'greystar-default': GreystarTheme,
  'greystar-simple': GreystarSimpleTheme,
  'greystar-unit': GreystarUnitTheme,
};

type ThemesConfiguration = typeof THEMES_CONFIGURATION;

export type ThemeKey = keyof ThemesConfiguration;

export interface ThemeProps {
  theme: ITheme;
  tour: Tour;
  cssVariables?: CSSProperties;
}

function ThemeNotSupported() {
  return (
    <h1
      style={{
        background: `var(--menu-background)`,
        color: `var(--menu-icon-color-default)`,
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        padding: 20,
      }}
    >
      This theme is not supported yet.
    </h1>
  );
}

function TourSuspended() {
  return (
    <div
      style={{
        background: `var(--menu-background)`,
        color: `var(--menu-icon-color-default)`,
        height: '100vh',
        position: 'static',
        textAlign: 'center',
        padding: 20,
        width: '100%',
      }}
    >
      <h1
        style={{
          marginTop: '20%',
          textTransform: 'uppercase',
        }}
      >
        403
      </h1>
      <h3
        style={{
          textTransform: 'uppercase',
        }}
      >
        there is a technical issue with your tour.
      </h3>
      <h3
        style={{
          textTransform: 'uppercase',
        }}
      >
        please contact us at (877) 658-0433
      </h3>
      <i className="fa fa-exclamation-triangle fa-5x"></i>
    </div>
  );
}

export default function Theme({ tour, theme, cssVariables }: ThemeProps) {
  const themeDebug = useURLParam(
    OverlayURLParam.THEME_DEBUG
  ) as keyof typeof THEMES_CONFIGURATION;

  if (tour.tourSuspended) return <TourSuspended />;

  if (Boolean(themeDebug) && THEMES_CONFIGURATION[themeDebug]) {
    const ThemeComponent = THEMES_CONFIGURATION[themeDebug];

    return (
      <ThemeComponent cssVariables={cssVariables} theme={theme} tour={tour} />
    );
  }

  if (tour.tourType === 'unit' || !theme.template)
    return (
      <TourBuilderUnitTheme
        cssVariables={cssVariables}
        theme={theme}
        tour={tour}
      />
    );

  const ThemeComponent = THEMES_CONFIGURATION[theme.template];

  if (!ThemeComponent) return <ThemeNotSupported />;

  return (
    <ThemeComponent cssVariables={cssVariables} theme={theme} tour={tour} />
  );
}
