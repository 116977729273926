import DOMPurify from 'dompurify';
import React from 'react';

import Image from '../Image/Image';
import Modal from '../Modal/Modal';
import { InfoHotspotData } from '../../hooks/useHotspots/useHotspots';

import styles from './InfoHotspotModal.module.scss';

interface InfoHotspotModalProps {
  data: InfoHotspotData | null;
  onClose: () => void;
}

export default function InfoHotspotModal({
  data,
  onClose,
}: InfoHotspotModalProps) {
  return (
    <Modal visible={!!data} onClose={onClose}>
      {!!data && (
        <>
          <h2>{data.title}</h2>
          <div className={styles.hotspotContainer}>
            {data.image && (
              <div className={styles.imageContainer}>
                <Image
                  alt="Info hotspot"
                  className={styles.image}
                  src={data.image.url}
                />
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.desc || ''),
              }}
            ></div>
          </div>
        </>
      )}
    </Modal>
  );
}
