import { useMemo, useState, useEffect } from 'react';

const CLIPBOARD_TEST_TEXT = '';

export function useClipboard() {
  const [isClipboardEnabled, setIsClipboardEnabled] = useState(false);

  useEffect(() => {
    const checkClipboardWriteText = async () => {
      try {
        await navigator.clipboard.writeText(CLIPBOARD_TEST_TEXT);

        setIsClipboardEnabled(true);
      } catch (error) {
        console.error(error);

        setIsClipboardEnabled(false);
      }
    };

    checkClipboardWriteText();
  }, []);

  return useMemo(
    () => ({
      isClipboardEnabled,
      writeTextToClipboard: isClipboardEnabled
        ? (text: string) => {
            return navigator.clipboard.writeText(text);
          }
        : undefined,
    }),
    [isClipboardEnabled]
  );
}
