import { GOOGLE_MAPS_DIRECTIONS_URL } from '../../../../../../../constants';
import { Tour } from '../../../../../../../types';
import safeURL from '../../../../../../../utils/safeURL/safeURL';
import useAnalyticsEvents from '../../../../../../../hooks/useAnalyticsEvents/useAnalyticsEvents';

import { ReactComponent as Phone } from './assets/phone.svg';
import { ReactComponent as Directions } from './assets/directions.svg';

import styles from './ContactMenuContent.module.scss';

interface ContactMenuContentProps {
  tour: Tour;
}

export default function ContactMenuContent({ tour }: ContactMenuContentProps) {
  const directionsLinkEncoded = `${GOOGLE_MAPS_DIRECTIONS_URL}${encodeURIComponent(
    `${tour.info.address.name} ${tour.info.address.streetAddress} ${tour.info.address.city} ${tour.info.address.postalCode}`
  )}`;
  const analyticsEvents = useAnalyticsEvents();
  const websiteUrl = safeURL(tour.info.website);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{tour.info.address.name || tour.title}</div>
      <div className={styles.address}>
        {tour.info.address.streetAddress}, {tour.info.address.city},{' '}
        {tour.info.address.state} {tour.info.address.postalCode}
        {websiteUrl ? (
          <a href={websiteUrl.toString()} target="_blank" rel="noreferrer">
            {websiteUrl.host}
          </a>
        ) : null}
        <div>{tour.info.phone}</div>
      </div>
      <div className={styles.actions}>
        {tour.contact.call.show ? (
          <a
            className={styles.action}
            href={`tel:${tour.info.phone}`}
            onClick={() => {
              analyticsEvents.conversion('call', 'Call', {
                conversion_phone: tour.info.phone,
              });
            }}
          >
            <Phone />
            Phone
          </a>
        ) : null}
        {tour.contact.directions.show ? (
          <a
            className={styles.action}
            href={directionsLinkEncoded}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              analyticsEvents.conversion('directions', 'Directions', {
                conversion_directions: directionsLinkEncoded,
              });
            }}
          >
            <Directions />
            Directions
          </a>
        ) : null}
      </div>
    </div>
  );
}
