import React from 'react';
import { useSpring, animated } from '@react-spring/web';

import { useAppSelector } from '../../../../../hooks/redux';
import useIsMobile from '../../../../../hooks/useIsMobile/useIsMobile';
import { getCarouselVisible } from '../../../../../stores/slices/ui';
import { Tour, MenuItemType } from '../../../../../types';
import { VIEW_CONTROLS_ID } from '../../../../../constants/ids';

import ControlPanel from './ControlPanel';

import styles from './ViewControls.module.scss';
interface ViewControlsProps {
  tour: Tour;
  onMenuButtonClick(id: string, type: MenuItemType, conversion: boolean): void;
}

export default function ViewControls({ tour }: ViewControlsProps) {
  const visibility = useAppSelector(getCarouselVisible);
  const isMobile = useIsMobile();

  const containerAnimation = useSpring({
    opacity: isMobile && visibility ? 0 : 1,
  });

  return (
    <animated.div className={styles.container} style={containerAnimation}>
      <div data-cy="view-controls" id={VIEW_CONTROLS_ID}>
        <ControlPanel tour={tour} />
      </div>
    </animated.div>
  );
}
