import { ReactNode, useRef } from 'react';

import { ReactComponent as Close } from '../../../../../../../components/Modal/assets/close.svg';
import hexToRGB, { HEX } from '../../../../../../../utils/hexToRGB/hexToRGB';
import onEnter from '../../../../../../../utils/onEnter/onEnter';
import useIsMobile from '../../../../../../../hooks/useIsMobile/useIsMobile';
import useOnClickOutside from '../../../../../../../hooks/useOnClickOutside/useOnClickOutside';
import useTheme from '../../../../../../../hooks/useTheme/useTheme';

import styles from './MenuDialog.module.scss';

interface MenuDialogProps {
  onClose: () => void;
  children: ReactNode;
  modal: string;
}

export default function MenuDialog({
  children,
  onClose,
  modal,
}: MenuDialogProps) {
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  useOnClickOutside(ref, onClose, 'tb-menu-item');

  return (
    <div className={styles.container}>
      <div
        className={`${styles.menuDialog} ${
          isMobile ? styles.menuDialogMobile : ''
        } ${modal === 'extended' ? styles.menuDialogUnits : ''}`}
        ref={ref}
        style={
          !isMobile
            ? modal === 'extended'
              ? { backgroundColor: hexToRGB('#ffffff' as HEX, 0.85) }
              : {
                  backgroundColor: hexToRGB(
                    theme.styles.modal.background as HEX,
                    0.9
                  ),
                }
            : {
                backgroundColor: theme.styles.modal.background,
              }
        }
      >
        <Close
          aria-label="Close modal"
          className={styles.close}
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
          data-cy="close-modal"
          tabIndex={0}
        />
        {children}
      </div>
    </div>
  );
}
