import { useMemo } from 'react';
import { Place } from '../../../../../types/place';

export default function useNearbyPlacesByType(places: Place[] | undefined) {
  return useMemo(() => {
    return [...(places || [])]
      .sort((a, b) => (a.rating! > b.rating! ? 1 : -1))
      .slice(0, 2);
  }, [places]);
}
