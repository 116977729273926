import DOMPurify from 'dompurify';
import React from 'react';

import Modal from '../Modal/Modal';
import { VideoHotspotData } from '../../hooks/useHotspots/useHotspots';
import { extractVideoId } from '../../utils/extractVideoId/extractVideoId';

import styles from './VideoHotspotModal.module.scss';

interface VideoHotspotModalProps {
  data: VideoHotspotData | null;
  onClose: () => void;
}

export default function VideoHotspotModal({
  data,
  onClose,
}: VideoHotspotModalProps) {
  const videoId = extractVideoId(data?.video?.youtube || '');

  return (
    <Modal visible={!!data} onClose={onClose}>
      {!!data && (
        <>
          <h2>{data.title}</h2>
          <div className={styles.hotspotContainer}>
            {videoId && (
              <div className={styles.videoContainer}>
                <iframe
                  className={styles.video}
                  title={data.title}
                  src={`https://youtube.com/embed/${videoId}`}
                />
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.desc || ''),
              }}
            ></div>
          </div>
        </>
      )}
    </Modal>
  );
}
