import clsx from 'clsx';

import { useFullscreen } from '../../hooks/useFullscreen/useFullscreen';
import ControlGroup from '../ControlGroup/ControlGroup';

import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';

import styles from './Fullscreen.module.scss';
import { FULLSCREEN_VIEW_CONTROL_ID } from '../../constants/ids';

interface FullscreenProps {
  className?: string;
}

export default function Fullscreen({ className }: FullscreenProps) {
  const { toggleFullscreen } = useFullscreen();

  return (
    <ControlGroup
      id={FULLSCREEN_VIEW_CONTROL_ID}
      className={clsx(styles.controlGroup, className)}
      controls={[
        {
          display: (
            <FullscreenIcon aria-label="Full Screen" data-cy="fullScreen" />
          ),
          onClick: () => {
            toggleFullscreen();
          },
        },
      ]}
    />
  );
}
